let videos = [];

var player = {
    playVideo: function(container, videoId) {
        if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {
            videos.push(videoId);
            window.onYouTubePlayerAPIReady = function() {
                videos.forEach((id) => {
                  player.loadPlayer(id, id);
                });
            };
            $.getScript('//www.youtube.com/player_api');
        } else {
            player.loadPlayer(container, videoId);
        }
    },
    loadPlayer: function(container, videoId) {
        window.myPlayer = new YT.Player(container, {
            playerVars: {
              autoplay: 0,        // Auto-play the video on load
              controls: 1,        // Show pause/play buttons in player
              showinfo: 1,        // Hide the video title
              modestbranding: 1,  // Hide the Youtube Logo
              loop: 0,            // Run the video in a loop
              rel: 0,               // Hide the full screen button
              cc_load_policy: 0,  // Hide closed captions
              iv_load_policy: 3,  // Hide the Video Annotations
              autohide: 0,        // Hide video controls when playing
              enablejsapi: 1      // Enable js api
            },
            height: '100%',
            width: '100%',
            videoId: videoId
        });
    }
};

$(document).ready(function() {
  loadYoutubeBlocks();
  activateAccordion();

  $('.homepage-blocks a').on('mouseenter', function(e) {
    $(this).find('.overlay').addClass('slideInUp');
  });
  $('.homepage-blocks a').on('mouseleave', function(e) {
    $(this).find('.overlay').removeClass('slideInUp');
  });

  $('.moveTop').on('click', function() {
    $("html, body").animate({ scrollTop: 0 }, "slow");
    return false;
  });

  $('.main-nav-trigger').on('click', function(e) {
    e.preventDefault();

    if($('.main-nav').is(':hidden')) {
      $('.main-nav').fadeIn();
    	$('.main-nav-trigger').addClass('active');
    } else {
      $('.main-nav').fadeOut();
    	$('.main-nav-trigger').removeClass('active');
    }
  });

  var lastScrollTop = 0, delta = 5;
  $(window).scroll(function () {
    var st = $(this).scrollTop();

    if(Math.abs(lastScrollTop - st) <= delta)
       return;


      if (st > lastScrollTop){
        if($(window).scrollTop() > 200) {
          if(!$('header').hasClass('minimized')) {
            $('header').addClass('minimized');
          }
        } else {
          if($('header').hasClass('minimized')) {
            $('header').removeClass('minimized');
          }
        }
      } else {
        if($('header').hasClass('minimized')) {
          $('header').removeClass('minimized');
        }
      }
    lastScrollTop = st;
  });

  var bodyBgImageData = $('.body-bg-image:first-of-type').data('imageurl');
  if(bodyBgImageData) {
    $('body').css({ 'background-image': 'url("' + bodyBgImageData + '")' });
  }
});

function loadNewBodyBgImage(nodeid) {
  var bodyBgImageData = '';
  $.each($('.body-bg-image'), function(key, value) {
    if($(value).data('id') == nodeid) {
      bodyBgImageData = $(value).data('imageurl');
    }
  });
  if(bodyBgImageData == '') {
    bodyBgImageData = $('.body-bg-image:first-of-type').data('imageurl');
  }
  if(bodyBgImageData != '') {
    $('body').css({ 'background-image': 'url("' + bodyBgImageData + '")' });
  }
}

function loadYoutubeBlocks() {
  $('.yt-embed').each(function(index) {
    player.playVideo($(this).data('id'),$(this).data('id'));
  });
}

function activateAccordion() {
  $('.accordion > h2').on('click', function(e) {
    if($(this).next('.content').is(':hidden')) {
      $(this).next('.content').slideDown();
      $(this).addClass('open');

      loadNewBodyBgImage($(this).data('nodeid'));
    } else {
      $(this).next('.content').slideUp();
      $(this).removeClass('open');

      var bodyBgImageData = $('.body-bg-image:first-of-type').data('imageurl');
      if(bodyBgImageData) {
        $('body').css({ 'background-image': 'url("' + bodyBgImageData + '")' });
      }
    }
  });
}
